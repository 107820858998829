import { User, UserUpdates, getUser, updateUser } from '@playful/api';
import { FromPromiseReturn, fromPromise } from '@playful/utils';
import useSWR from 'swr';

export function useUser({ id, fallbackData }: { id?: string; fallbackData?: User }) {
  const { data, error, mutate } = useSWR<User | undefined>(
    id ? `users/${id}` : null,
    () => (id ? getUser(id) : undefined),
    { fallbackData },
  );

  const updateUserMutation = (updatedUser: UserUpdates) =>
    mutate(() => updateUser(id!, updatedUser), {
      optimisticData: { ...data!, ...updatedUser },
      populateCache: true,
      revalidate: false,
      rollbackOnError: true,
    });

  return {
    user: data,
    updateUser: (updatedUser: UserUpdates): FromPromiseReturn<User | undefined> =>
      fromPromise(updateUserMutation(updatedUser)),
    error,
  };
}
