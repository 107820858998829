import { Image, ListItem, OrderedList, UnorderedList } from '@chakra-ui/react';
import { Box, Heading, Link, Tag, Text, useIsSmallScreen } from '@playful/design_system';
import type { Notice } from '@playful/sanity';
import { PortableText } from '@portabletext/react';
import React from 'react';

import { urlFor } from '../../sanity';

export type NoticeItemProps = {
  item: Notice;
};

const variants = {
  major_announcement: {
    background: 'yellow.500',
    tagText: '✧ major announcement!',
    tagTextColor: 'gray.900',
    tagColor: 'white',
  },
  new_feature: {
    background: 'white',
    tagText: 'new feature',
    tagTextColor: '#751EEF',
    tagColor: '#EBD1FE',
  },
  improvement: {
    background: 'white',
    tagText: 'improvement',
    tagTextColor: '#2B84D8',
    tagColor: '#D7F6FE',
  },
  bug_fix: {
    background: 'white',
    tagText: 'bug fix',
    tagTextColor: '#F22C79',
    tagColor: '#FED5D4',
  },
};

// These are passed to the PortableText component to render the rich text
export const textComponents = {
  block: {
    normal: ({ children }: { children?: any }) => <Text mt={3}>{children}</Text>,
  },
  list: {
    bullet: ({ children }: { children?: any }) => <UnorderedList>{children}</UnorderedList>,
    number: ({ children }: { children?: any }) => <OrderedList>{children}</OrderedList>,
  },
  listItem: ({ children }: { children?: any }) => <ListItem>{children}</ListItem>,
  marks: {
    link: ({ children, value }: { children?: any; value?: { href?: string } }) => {
      return (
        <Link href={value?.href} target={'_blank'} isExternal>
          {children}
        </Link>
      );
    },
  },
};

export function NoticeItem({ item }: NoticeItemProps) {
  const variant = variants[item.cardType] || {};
  const isSmScreen = useIsSmallScreen(true);
  const imageWidth = 324;
  const imageUrl = item.image && urlFor(item.image).width(imageWidth).url();

  return (
    <Box
      padding={6}
      background={variant.background}
      borderBottom='1px solid'
      borderColor='gray.100'
    >
      <Tag
        fontSize='xs'
        minH='22px'
        w='fit-content'
        px={1.5}
        py={0.5}
        color={variant.tagTextColor}
        background={variant.tagColor}
      >
        {variant.tagText}
      </Tag>
      <Box mt={4} flexWrap='wrap' display='flex' gap={6} justifyContent='space-between'>
        <Box flex={isSmScreen ? '1 1 auto' : 1}>
          <Heading as='h2' lineHeight={7} fontSize={18} color='gray.700' fontWeight='bold' mb={3}>
            {item.title}
          </Heading>
          <Text as='div' color='gray.700'>
            <PortableText value={item.bodyRichText} components={textComponents} />
          </Text>
          {item.learnMoreUrl && (
            <Link
              href={item.learnMoreUrl}
              isExternal
              fontSize='sm'
              variant='underline'
              color='gray.700'
            >
              Learn More
            </Link>
          )}
        </Box>
        {imageUrl && (
          <Box flex={isSmScreen ? '1 1 auto' : 1}>
            <Image
              src={imageUrl}
              alt={item.title}
              width={imageWidth}
              minW='100%'
              border='1px solid'
              borderColor='gray.900'
              borderRadius='md'
            />
          </Box>
        )}
      </Box>
    </Box>
  );
}
