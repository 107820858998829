import { useBreakpointValue } from '@chakra-ui/react';
import { Box, Button, ChevronDownIcon, ChevronUpIcon, MenuButton } from '@playful/design_system';
import { MenuSubMenu, MenuSubMenuButtonProps } from '@playful/frontend/components/MenuSubMenu';
import { useNavigationLinks } from '@playful/frontend/user/useNavigationLinks';
import React from 'react';

export type RouteItem = {
  path: string;
  label: string;
  disabled?: boolean;
};

export type HeaderProps = { navItems: RouteItem[] };

const ExplorerMenuButton = ({
  label,
  withArrow,
  isOpen,
  onClose,
  onOpen,
}: MenuSubMenuButtonProps) => {
  const icon = isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />;
  const buttonSize = useBreakpointValue({ base: 'sm', lg: 'md' }, { fallback: 'lg' });

  return (
    <MenuButton
      as={Button}
      variant='ghost'
      colorScheme='dark'
      rightIcon={withArrow ? icon : undefined}
      onClick={() => (isOpen ? onClose?.() : onOpen?.())}
      size={buttonSize}
      sx={{
        svg: {
          h: 5,
          w: 5,
        },
      }}
    >
      {label}
    </MenuButton>
  );
};

export function ExplorerNavigationAppBarSection() {
  const { getNavigationRoutes } = useNavigationLinks();
  const subRoutes = getNavigationRoutes();

  if (!subRoutes) return null;
  return (
    <>
      {subRoutes.map((menuItems, idx) => {
        const key = typeof menuItems.label === 'string' ? menuItems.label : idx;
        return (
          <Box mr={3} key={key}>
            <MenuSubMenu
              TopMenuButton={ExplorerMenuButton}
              label={menuItems.label}
              items={menuItems.items}
              withArrow={menuItems.withArrow}
            />
          </Box>
        );
      })}
    </>
  );
}
