import { getConfig } from '@playful/config';
import { QueryParams, createClient } from '@sanity/client';
import imageUrlBuilder from '@sanity/image-url';
import type { Image } from '@sanity/types';
import RouterDefault from 'next/router';

const useCdn = process.env.NODE_ENV === 'production';

const config = getConfig();

export const client = createClient({
  projectId: config.sanityProjectId,
  dataset: config.sanityDataset,
  apiVersion: config.sanityApiVersion,
  useCdn: useCdn,
  perspective: 'published',
});

const DEFAULT_PARAMS = {} as QueryParams;

export async function sanityFetch<QueryResponse>(
  query: string,
  params: QueryParams = DEFAULT_PARAMS,
  options?: { draftMode?: boolean },
): Promise<QueryResponse> {
  const draftMode =
    options?.draftMode || (typeof window !== 'undefined' && RouterDefault.isPreview);
  const token = config.sanityApiReadToken;
  if (draftMode && !token) {
    throw new Error('The `SANITY_API_READ_TOKEN` environment variable is required.');
  }
  return client.fetch<QueryResponse>(query, params, {
    ...(draftMode && {
      token: token,
      useCdn: false,
      perspective: 'previewDrafts',
    }),
  });
}

const builder = imageUrlBuilder(client);

export function urlFor(source: Image) {
  return builder.image(source);
}
