import { ButtonProps, useDisclosure } from '@chakra-ui/react';
import {
  DarkPresentIcon,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  PresentIcon,
  Text,
  useIsSmallScreen,
} from '@playful/design_system';
import type { Notice } from '@playful/sanity';
import { customEvent } from '@playful/telemetry';
import groq from 'groq';
import React, { useCallback, useEffect } from 'react';
import useSWR from 'swr';

import { sanityFetch } from '../../sanity';
import { useUserContext } from '../../user/UserContext';
import { useUser } from '../../user/useUser';
import { NoticeItem } from './NoticeItem';

export const getNoticesQuery = groq`*[_type == "notice"]`;

export async function getNotices() {
  return sanityFetch<Notice[]>(getNoticesQuery);
}

export function NoticesButton(props: ButtonProps) {
  const { isOpen, onClose: close, onOpen: open } = useDisclosure();
  const { user: authUser, isLoggedInUser } = useUserContext();
  const { user, updateUser } = useUser({ id: authUser?.id });
  const setLastReadNotice = useCallback(() => {
    updateUser({ lastReadNotice: Math.floor(new Date().getTime() / 1000) });
  }, [updateUser]);

  const onOpen = useCallback(() => {
    customEvent('notices-open');
    setLastReadNotice();
    open();
  }, [open, setLastReadNotice]);

  const onClose = useCallback(() => {
    customEvent('notices-close');
    close();
  }, [close]);

  const { data, error } = useSWR(isLoggedInUser ? 'notices' : null, getNotices, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });
  const isSmScreen = useIsSmallScreen();
  const sortedData = data?.slice().sort((a, b) => {
    return new Date(b._createdAt).getTime() - new Date(a._createdAt).getTime();
  });
  const hasLastReadNotice = !!user?.lastReadNotice || !!authUser?.lastReadNotice;
  const lastReadNotice = new Date((user?.lastReadNotice || authUser?.lastReadNotice || 0) * 1000);
  const hasNewNotice =
    !!sortedData && sortedData.findIndex((item) => new Date(item._createdAt) > lastReadNotice) >= 0;
  const isReady = !error && isLoggedInUser;

  useEffect(() => {
    // don't show for users that literally just created an account
    if (!hasLastReadNotice) {
      setLastReadNotice();
      return;
    }

    if (isReady && hasNewNotice) onOpen();
  }, [isReady, onOpen, hasNewNotice, hasLastReadNotice, setLastReadNotice]);

  if (!isReady) return null;

  return (
    <>
      <IconButton
        icon={<DarkPresentIcon />}
        onClick={onOpen}
        aria-label='notices'
        variant=''
        {...props}
      />
      <Modal
        scrollBehavior='inside'
        size={isSmScreen ? 'almost-full-mobile' : undefined}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent
          overflow={isSmScreen ? undefined : 'hidden'}
          maxW={isSmScreen ? undefined : '720px'}
        >
          <ModalHeader display='flex' gap={3} alignItems='center' h='56px' p='16px'>
            <PresentIcon />
            <Text fontWeight='semibold' color={'gray.900'} flexGrow={1} display='flex'>
              what&apos;s new in Hatch
            </Text>
            <ModalCloseButton />
          </ModalHeader>
          <ModalBody p={0}>
            {sortedData?.map((item) => <NoticeItem key={item._id} item={item} />)}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
