var __jsx = React.createElement;
import dynamic from 'next/dynamic';
import React from 'react';
var AppToasts = dynamic(function () {
  return import('@playful/workbench/components/AppToasts');
}, {
  ssr: false,
  loadableGenerated: {
    webpack: function webpack() {
      return [require.resolveWeak('@playful/workbench/components/AppToasts')];
    }
  }
});
export function withPage(Page) {
  return function WrappedPage(props) {
    return __jsx(React.Fragment, null, __jsx(Page, props), __jsx(AppToasts, null));
  };
}